/* eslint-disable no-console */

import { AxiosError } from 'axios'
import Boom from 'boom'
import { compact, flatten, intersection, pick } from 'lodash'
import { inspect } from 'lib/inspect'
type colorful = (str: string) => string
let colors: { magenta: colorful; red: colorful; cyan: colorful; yellow: colorful }
try {
  colors = require(`colors/safe`)
} catch {
  colors = {
    magenta: (str) => str,
    red: (str) => str,
    cyan: (str) => str,
    yellow: (str) => str,
  }
}

let rtracer: { id: () => string } | null = null
try {
  rtracer = require(`cls-rtracer`)
} catch (e) {
  //
}

function format(...args: any[]) {
  if (typeof process !== `undefined` && process.env?.FLATTEN_LOGGING) {
    try {
      return args.map((a) => {
        if (typeof a === `string`) return a.replace(/\n/g, ` `)
        if (a instanceof Error) {
          return (a.name + `: ` + a.message + `: ` + a.stack).replace(/\n/g, ` `)
        }
        return JSON.stringify(a).replace(/\n/g, ` `)
      })
    } catch {
      return args
    }
  } else {
    return args
  }
}

/**
 * log something
 * @param args
 */
export function log(message: string, ...args: unknown[]): void {
  if (typeof process !== `undefined` && process.env.TESTING) {
    debug(`test`, message, ...clean(args))
    return
  }
  let id = rtracer?.id()
  if (id) {
    console.log(...format(new Date().toISOString(), `trace:${id}`, message, ...clean(args)))
  } else {
    console.log(...format(new Date().toISOString(), message, ...clean(args)))
  }
}

/**
 * debug something, meaning it gets logged if the associated debug tags are enabled
 * @param tags the tags associated with the debug state
 * @param message the message to debug. all debug statements should start with a string to represent the "what" before passing in objects to be debugged.
 * @param args
 */
export function debug(tags: string | string[], message: string, ...args: unknown[]): void {
  if (tagsActive(tags)) {
    let id = rtracer?.id()
    if (id) {
      // nosemgrep
      console.log(...format(colors.cyan(`[${tags}]`), new Date().toISOString(), `trace:${id}`, message, ...clean(args)))
    } else {
      // nosemgrep
      console.log(...format(colors.cyan(`[${tags}]`), new Date().toISOString(), message, ...clean(args)))
    }
  }
}

export function trace(tags: string | string[], message: string, ...args: unknown[]): void {
  if (tagsActive(tags)) {
    // nosemgrep
    console.trace(...format(`[${tags}]`.cyan, message, ...clean(args)))
  }
}

/**
 * log an error
 * @param message message explaining what the error is.
 * @param args args to log. usually the error object.
 */
export function logError(message: string, ...args: any[]): void {
  let id = rtracer?.id()

  trace(`etimedout`, `error stack`)
  let haveStack = args.some((a) => a?.stack)
  let stack = haveStack ? `` : new Error(`trace`).stack

  if (id) {
    console.error(
      ...format(
        new Date(),
        colors.red(`[error]`),
        `trace:${id}`,
        colors.red(message) || message,
        ...clean(args),
        stack,
      ),
    )
  } else {
    console.error(...format(new Date(), colors.red(`[error]`), colors.red(message) || message, ...clean(args), stack))
  }
}

export function logWarn(message: string, ...args: any[]): void {
  if (typeof process !== `undefined` && process.env.TESTING) {
    debug(`test`, message, ...clean(args))
    return
  }
  let id = rtracer?.id()

  if (id) {
    console.warn(
      ...format(
        new Date(),
        colors.yellow(`[warning]`),
        `trace:${id}`,
        colors.yellow(message) || message,
        ...clean(args),
      ),
    )
  } else {
    console.warn(...format(new Date(), colors.yellow(`[warning]`), colors.yellow(message) || message, ...clean(args)))
  }
}

/**
 * check if the given tags are currently active
 * @param tags
 */
export function tagsActive(tags: string | string[]): boolean {
  let activeTags: any

  if (typeof localStorage !== `undefined`) {
    activeTags = localStorage.getItem(`DEBUG_TAGS`)
  } else {
    activeTags = process?.env?.DEBUG_TAGS
  }

  if (!activeTags) {
    return false
  }
  if (activeTags !== `true`) {
    activeTags = compact(activeTags.split(/,/).map((t) => t.trim()))
    if (intersection(activeTags, flatten([tags])).length === 0) {
      return false
    }
  }
  return true
}

function clean(args: any[]): string[] {
  return args?.map((arg) => {
    if (Array.isArray(arg)) return clean(arg)
    if (arg?.isAxiosError || arg instanceof AxiosError || arg?.[`name`] === `AxiosError`) {
      return inspect(
        pick(
          arg,
          `message`,
          `name`,
          `code`,
          `status`,
          `config.url`,
          `config.method`,
          `config.data`,
          `config.headers`,
          `response.data`,
          `response.headers`,
          `stack`,
        ),
      )
    } else if (typeof arg?.[`toJSON`] === `function`) {
      return arg.toJSON()
    } else if (Boom.isBoom(arg)) {
      return `- BoomError: ${inspect(arg.output, false)}-${arg.message}-${arg.stack}`
    }
    return arg
  })
}
